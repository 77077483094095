<template>
  <div>
    <b-card no-body class="mb-3">
      <b-card-header class="with-elements">
        <span class="card-header-title mr-2"
          ><i class="fa fa-list-alt"></i> Visualización:
          {{ reporte.titulo }}</span
        >
        <div class="card-header-elements"></div>
      </b-card-header>
      <b-card-body>
        <div class="row">
          <div class="col-md-6">
            <div v-if="!loading && tipoChart == 'DATA-CHART'">
              <data-chart-card
                class="w-100"
                :colores="configStyle.colores"
                :title="configDataChart.title"
                :height="configDataChart.height"
                :defaultView="configDataChart.defaultView"
                :section="configDataChart.section"
                :code="configDataChart.code"
                :filaTieneTitulo="configDataChart.filaTieneTitulo"
                :allowTableExceedHeight="configDataChart.allowTableExceedHeight"
                :pivotTable="configDataChart.pivotTable"
                :pivotReportData="configDataChart.pivotReportData"
                :chartType="configDataChart.chartType"
                :key="configDataChart.componentForceUpdateKey"
              ></data-chart-card>
            </div>

            <div v-if="!loading && tipoChart == 'SINGLE'">
              <single-data-card
                class="w-50 border"
                :colores="configStyle.colores"
                :title="configSingle.title"
                :prefix="configSingle.prefix"
                :suffix="configSingle.suffix"
                :iconClass="configSingle.iconClass"
                :section="configSingle.section"
                :code="configSingle.code"
                :filaTieneTitulo="configSingle.filaTieneTitulo"
                :column="configSingle.column"
                :columnIndex="configSingle.columnIndex"
                :rowIndex="configSingle.rowIndex"
                :pivotReportData="configSingle.pivotReportData"
                :key="configSingle.componentForceUpdateKey"
                ref="markdowndetails"
              >
              </single-data-card>
              <hr />

              <div
                v-if="
                  configSingle.componentForceUpdateKey !=
                    configSingle.rawDataForceUpdateKey
                "
              >
                <div
                  class="btn btn-default"
                  @click="
                    configSingle.rawDataForceUpdateKey =
                      configSingle.componentForceUpdateKey
                  "
                >
                  Mostrar datos de origen
                </div>
              </div>

              <div
                v-if="
                  configSingle.componentForceUpdateKey ==
                    configSingle.rawDataForceUpdateKey
                "
              >
                <h4>Origen de datos:</h4>
              </div>

              <div
                class="table-responsive mb-0"
                v-if="
                  $refs.markdowndetails &&
                    configSingle.componentForceUpdateKey ==
                      configSingle.rawDataForceUpdateKey
                "
                :key="configSingle.rawDataForceUpdateKey"
              >
                <table class="table table-sm table-cell-ellipsis">
                  <tr>
                    <th
                      style="width:30px"
                      class="text-center table-cell-prevent-ellipsis"
                    >
                      <span>Fila</span>
                    </th>
                    <th v-if="$refs.markdowndetails.filaTieneTitulo"></th>
                    <th
                      class="text-center"
                      v-for="(columna, index) in $refs.markdowndetails.rawData
                        .columnas"
                      :key="index"
                    >
                      <span>{{
                        "Col. " +
                          ($refs.markdowndetails.filaTieneTitulo
                            ? index
                            : index)
                      }}</span>
                    </th>
                  </tr>
                  <tr>
                    <th class="table-cell-prevent-ellipsis"></th>
                    <th v-if="$refs.markdowndetails.filaTieneTitulo"></th>
                    <th
                      class="text-center"
                      v-for="(columna, index) in $refs.markdowndetails.rawData
                        .columnas"
                      :key="index"
                    >
                      <span>{{ columna }}</span>
                    </th>
                  </tr>
                  <tr
                    v-for="(fila, index) in $refs.markdowndetails.rawData.filas"
                    :key="index"
                  >
                    <th class="table-cell-prevent-ellipsis text-center ">
                      <span>{{ index }}</span>
                    </th>
                    <th
                      class="text-right"
                      v-if="$refs.markdowndetails.filaTieneTitulo"
                    >
                      <span>{{ fila.titulo }}</span>
                    </th>
                    <td
                      class="text-center"
                      v-for="(valor, indexVal) in fila.valores"
                      :key="indexVal"
                    >
                      <span>{{ valor }}</span>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <form id="frmConfiguracion">
              <b-card no-body class="mb-3">
                <b-card-header class=" py-4 bg-light">
                  <span class="card-label font-weight-bold mr-2"
                    >Visualización</span
                  >
                </b-card-header>
                <b-card-body>
                  <div class="form-row">
                    <div class="form-group col-md-12">
                      <label class="form-label">Ancho de componente</label>
                      <input
                        v-model="configStyle.colClass"
                        type="text"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-12">
                      <label class="form-label">Orden</label>
                      <input
                        v-model="configStyle.orden"
                        type="number"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-12 mb-0">
                      <label class="form-label">Esquema de colores</label>
                      <select
                        v-model="configStyle.colores"
                        type="text"
                        class="form-control"
                      >
                        <option :value="null">- Por defecto -</option>
                        <option value="SEMAFORO">SEMAFORO</option>
                        <option value="SEMAFORO-INV">SEMAFORO-INV</option>
                        <option value="VERDE-ROJO-GRIS">VERDE-ROJO-GRIS</option>
                        <option value="ROJO-VERDE-GRIS">ROJO-VERDE-GRIS</option>
                        <option value="GRIS-VERDE-ROJO">GRIS-VERDE-ROJO</option>
                        <option value="GRIS-ROJO-VERDE">GRIS-ROJO-VERDE</option>
                      </select>
                    </div>
                  </div>
                </b-card-body>
              </b-card>

              <b-card no-body class="mb-3">
                <b-card-header class=" py-4 bg-light">
                  <span class="card-label font-weight-bold mr-2"
                    >Configuración</span
                  >
                </b-card-header>
                <b-card-body>
                  <div class="form-row">
                    <div class="form-group col-md-12">
                      <label class="form-label">Tipo de componente</label>
                      <select
                        v-model="tipoChart"
                        type="text"
                        class="form-control"
                      >
                        <option value="DATA-CHART">Gráfico / Tabla</option>
                        <option value="SINGLE">Indicador</option>
                      </select>
                    </div>
                  </div>

                  <div v-if="tipoChart == 'DATA-CHART'">
                    <div class="form-row">
                      <div class="form-group col-md-12">
                        <label class="form-label">Título</label>
                        <input
                          v-model="configDataChart.title"
                          type="text"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-md-12">
                        <label class="form-label">Altura</label>
                        <input
                          v-model="configDataChart.height"
                          type="number"
                          class="form-control"
                        />
                        <div>
                          <input
                            v-model="configDataChart.allowTableExceedHeight"
                            type="checkbox"
                            class=""
                            style="position: relative; top: 2px;"
                          />
                          Ajustar al contenido de la tabla. Mostrar todos los
                          datos de la tabla sin importar el tamaño.
                        </div>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col-md-12">
                        <label class="form-label">Título en filas</label>
                        <div>
                          <input
                            v-model="configDataChart.filaTieneTitulo"
                            type="checkbox"
                            class=""
                            style="position: relative; top: 2px;"
                          />
                          ¿La primera columna de cada fila es un título?
                          <i
                            class="fa fa-exclamation-triangle text-warning"
                          ></i>
                          Necesario para generar gráficos.
                        </div>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col-md-12">
                        <label class="form-label"
                          >Pivote de tabla de datos</label
                        >
                        <div>
                          <input
                            v-model="configDataChart.pivotTable"
                            type="checkbox"
                            class=""
                            style="position: relative; top: 2px;"
                          />
                          Invertir filas y columnas para mostrar la transpuesta.
                        </div>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col-md-12">
                        <label class="form-label">Tipo de gráfico</label>
                        <select
                          v-model="configDataChart.chartType"
                          type="text"
                          class="form-control"
                        >
                          <option :value="null">- Sin gráfico -</option>
                          <option value="pie">Gráfico de Pie</option>
                          <option value="bar">Gráfico de Barras</option>
                          <option value="column">Gráfico de Columnas</option>
                        </select>
                      </div>
                    </div>

                    <div
                      class="form-row"
                      v-if="configDataChart.chartType != null"
                    >
                      <div class="form-group col-md-12">
                        <label class="form-label"
                          >Pivote de fuente de gráfico</label
                        >
                        <div>
                          <input
                            v-model="configDataChart.pivotReportData"
                            type="checkbox"
                            class=""
                            style="position: relative; top: 2px;"
                          />
                          Invertir series y categorías para mostrar la
                          transpuesta.
                        </div>
                      </div>
                    </div>
                    <div
                      class="form-row"
                      v-if="configDataChart.chartType != null"
                    >
                      <div class="form-group col-md-12">
                        <label class="form-label">Vista incial</label>
                        <select
                          v-model="configDataChart.defaultView"
                          type="text"
                          class="form-control"
                        >
                          <option :value="null">- Por defecto -</option>
                          <option value="chart">Gráfico</option>
                          <option value="table">Tabla de datos</option>
                        </select>
                      </div>
                    </div>
                    <hr />
                    <div class="form-row">
                      <div class="form-group col-md-12">
                        <div>
                          <input
                            v-model="configDataChart.showRenderCode"
                            type="checkbox"
                            class=""
                            style="position: relative; top: 2px;"
                          />
                          Mostrar código de generación.
                        </div>
                        <textarea
                          v-show="configDataChart.showRenderCode"
                          v-model="chartRenderCode"
                          class="form-control"
                          id="chartRenderCode"
                          readonly
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div v-if="tipoChart == 'SINGLE'">
                    <div class="form-row">
                      <div class="form-group col-md-12">
                        <label class="form-label">Título</label>
                        <input
                          v-model="configSingle.title"
                          type="text"
                          class="form-control"
                        />
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col-md-12">
                        <label class="form-label">Prefijo</label>
                        <input
                          v-model="configSingle.prefix"
                          type="text"
                          class="form-control"
                        />
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col-md-12">
                        <label class="form-label">Sufijo</label>
                        <input
                          v-model="configSingle.suffix"
                          type="text"
                          class="form-control"
                        />
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col-md-12">
                        <label class="form-label">Ícono</label>
                        <input
                          v-model="configSingle.iconClass"
                          type="text"
                          class="form-control"
                        />
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col-md-12">
                        <label class="form-label">Título en filas</label>
                        <div>
                          <input
                            v-model="configSingle.filaTieneTitulo"
                            type="checkbox"
                            class=""
                            style="position: relative; top: 2px;"
                          />
                          ¿La primera columna de cada fila es un título?
                        </div>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col-md-12">
                        <label class="form-label"
                          >Pivote de tabla de datos</label
                        >
                        <div>
                          <input
                            v-model="configSingle.pivotTable"
                            type="checkbox"
                            class=""
                            style="position: relative; top: 2px;"
                          />
                          Invertir filas y columnas para mostrar la transpuesta.
                        </div>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col-md-12">
                        <label class="form-label">Indicador</label>
                        <div>
                          <input
                            v-model="configSingle.customRowColumn"
                            type="checkbox"
                            class=""
                            style="position: relative; top: 2px;"
                          />
                          Personalizar indicador (por fila y/o columna).
                        </div>
                      </div>
                    </div>

                    <div class="form-row" v-if="configSingle.customRowColumn">
                      <div class="form-group col-md-12">
                        <label class="form-label">Nombre de columna</label>
                        <input
                          v-model="configSingle.column"
                          type="number"
                          min="0"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="form-row" v-if="configSingle.customRowColumn">
                      <div class="form-group col-md-12">
                        <label class="form-label"
                          >Índice de columna (empieza en 0)</label
                        >
                        <input
                          v-model="configSingle.columnIndex"
                          type="number"
                          min="0"
                          class="form-control"
                        />
                      </div>
                    </div>

                    <div class="form-row" v-if="configSingle.customRowColumn">
                      <div class="form-group col-md-12">
                        <label class="form-label"
                          >Índice de fila (empieza en 0)</label
                        >
                        <input
                          v-model="configSingle.rowIndex"
                          type="number"
                          min="0"
                          class="form-control"
                        />
                      </div>
                    </div>

                    <hr />
                    <div class="form-row">
                      <div class="form-group col-md-12">
                        <div>
                          <input
                            v-model="configSingle.showRenderCode"
                            type="checkbox"
                            class=""
                            style="position: relative; top: 2px;"
                          />
                          Mostrar código de generación.
                        </div>
                        <textarea
                          v-show="configSingle.showRenderCode"
                          v-model="singleRenderCode"
                          class="form-control"
                          id="singleRenderCode"
                          readonly
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </b-card-body>
              </b-card>
            </form>
          </div>
        </div>
      </b-card-body>
      <b-card-footer class="text-right">
        <div class="col-md-12">
          <b-btn class="mr-2" @click="$router.go(-1)"
            ><i class="fa fa-arrow-left"></i> Regresar</b-btn
          >
          <b-btn variant="primary" @click="doSave()"
            ><i class="fa fa-save"></i> Guardar</b-btn
          >
        </div>
      </b-card-footer>
    </b-card>
  </div>
</template>

<script>
import Vue from "vue";
import $ from "jquery";
import "parsleyjs";

import dataChartCard from "@/components/report/data-chart-card";
import singleDataCard from "@/components/report/single-data-card";

import Swal from "sweetalert2";

/*intervalos de tiempo de Inactividad*/
import {
  INACTIVE_USER_TIME_THRESHOLD,
  USER_ACTIVITY_THROTTLER_TIME,
} from "@/helpers/constants.js";

export default {
  name: "view-data",
  metaInfo: {
    title: "Visualizar Reporte",
  },
  components: {
    dataChartCard,
    singleDataCard,
  },
  data: () => ({
    loading: true,
    tipoChart: "DATA-CHART",

    reporte: {
      reporteDataId: null,
      seccion: null,
      codigo: null,
      titulo: null,
      subTitulo: null,
      descripcion: null,
      sentenciaSelect: null,
      sentenciaOrderBy: null,
      sentenciaJsonFormat: null,
      sentenciaParametros: "",
      configuracionVisualizacion: null,

      esEliminado: false,
    },
    configDataChart: {
      title: "",
      height: null,
      defaultView: null, //["table", "chart"],
      section: "",
      code: "",
      filaTieneTitulo: true,
      allowTableExceedHeight: true,
      pivotTable: false,
      pivotReportData: false,
      chartType: null, //["pie", "chart", "bar"],
      componentForceUpdateKey: 0,
    },
    configSingle: {
      title: "",
      prefix: null,
      suffix: null,
      iconClass: "fa fa-chart-area",
      section: null,
      code: null,
      filaTieneTitulo: false,
      column: null,
      columnIndex: null,
      rowIndex: null,
      pivotReportData: false,
      componentForceUpdateKey: 0,
      rawDataForceUpdateKey: -1,
    },
    configStyle: {
      colClass: "col-sm-6 col-xl-4 col-xxl-3",
      orden: 1,
      colores: null,
    },
    /*variables de inactividad*/
    isInactive: false,
    userActivityThrottlerTimeout: null,
    userActivityTimeout: null,
  }),
  computed: {
    chartRenderCode: function() {
      var code = "";
      code += "<data-chart-card " + "\n";
      code += ' class="w-100"' + "\n";
      code += " :title=\"'" + this.configDataChart.title + "'\"" + "\n";
      code += ' :height="' + this.configDataChart.height + '"' + "\n";
      code +=
        " :defaultView=\"'" + this.configDataChart.defaultView + "'\"" + "\n";
      code += " :section=\"'" + this.configDataChart.section + "'\"" + "\n";
      code += " :code=\"'" + this.configDataChart.code + "'\"" + "\n";
      code +=
        " :filaTieneTitulo=\"'" +
        this.configDataChart.filaTieneTitulo +
        "'\"" +
        "\n";

      code +=
        ' :allowTableExceedHeight="' +
        this.configDataChart.allowTableExceedHeight +
        '"' +
        "\n";
      code += ' :pivotTable="' + this.configDataChart.pivotTable + '"' + "\n";
      code +=
        ' :pivotReportData="' +
        this.configDataChart.pivotReportData +
        '"' +
        "\n";
      code += " :chartType=\"'" + this.configDataChart.chartType + "'\"" + "\n";
      code += " ></data-chart-card>";

      this.$nextTick(function() {
        var element = document.getElementById("chartRenderCode");
        element.style.height = "5px";
        element.style.height = element.scrollHeight + 5 + "px";
      });

      return code;
    },
    singleRenderCode: function() {
      var code = "";

      code += "<single-data-card " + "\n";
      code += '   class="w-50 border"' + "\n";
      code += "   :title=\"'" + this.configSingle.title + "'\"" + "\n";
      code += "   :prefix=\"'" + this.configSingle.prefix + "'\"" + "\n";
      code += "   :suffix=\"'" + this.configSingle.suffix + "'\"" + "\n";
      code += "   :iconClass=\"'" + this.configSingle.iconClass + "'\"" + "\n";
      code += "   :section=\"'" + this.configSingle.section + "'\"" + "\n";
      code += "   :code=\"'" + this.configSingle.code + "'\"" + "\n";
      code +=
        "   :filaTieneTitulo=\"'" +
        this.configSingle.filaTieneTitulo +
        "'\"" +
        "\n";

      if (this.configSingle.column != "") {
        code += "   :column=\"'" + this.configSingle.column + "'\"" + "\n";
      }
      if (this.configSingle.columnIndex != "") {
        code +=
          '   :columnIndex="' + this.configSingle.columnIndex + '"' + "\n";
      }
      if (this.configSingle.rowIndex != "") {
        code += '   :rowIndex="' + this.configSingle.rowIndex + '"' + "\n";
      }
      code +=
        '   :pivotReportData="' +
        this.configSingle.pivotReportData +
        '"' +
        "\n";
      code += "></single-data-card>";

      this.$nextTick(function() {
        var element = document.getElementById("singleRenderCode");
        element.style.height = "5px";
        element.style.height = element.scrollHeight + 5 + "px";
      });

      return code;
    },
  },
  methods: {
    fetchReporte: async function(reporteDataId) {
      debugger;
      var model = {
        reporteDataId: reporteDataId,
      };

      await this.$http
        .get(this.apiRoute("ReportData", "GetReporteById"), { params: model })
        .then(function(response) {
          if (!this.procesarRespuestaErrorDefecto(response)) {
            this.reporte = response.body.data;

            if (this.reporte.configuracionVisualizacion) {
              var dataGuardadaObj = JSON.parse(
                this.reporte.configuracionVisualizacion || "{}"
              );
              this.tipoChart = dataGuardadaObj.tipoChart;
              if (dataGuardadaObj.configDataChart) {
                Object.assign(
                  this.configDataChart,
                  dataGuardadaObj.configDataChart
                );
              }
              if (dataGuardadaObj.configSingle) {
                Object.assign(this.configSingle, dataGuardadaObj.configSingle);
              }
              if (dataGuardadaObj.configStyle) {
                Object.assign(this.configStyle, dataGuardadaObj.configStyle);
              }
              this.configSingle.rawDataForceUpdateKey = -1;
            } else {
              this.configDataChart.title = this.reporte.titulo;
              this.configSingle.title = this.reporte.titulo;
            }

            this.configDataChart.section = this.reporte.seccion;
            this.configDataChart.code = this.reporte.codigo;

            this.configSingle.section = this.reporte.seccion;
            this.configSingle.code = this.reporte.codigo;
            this.loading = false;

            this.showLoading(false);
          }
        }, this.procesarRespuestaExcepcionDefecto);
    },
    doSave: async function() {
      var isValid = $("#frmConfiguracion")
        .parsley()
        .validate();

      if (!isValid) {
        await this.sweetAlert(
          "Hay datos pendientes o incorrectos. Revise los campos e intente nuevamente."
        );
        return;
      }

      if (!(await this.sweetConfirm("¿Desea guardar la configuración?"))) {
        return;
      }

      var saveConfig = {
        tipoChart: this.tipoChart,
        configDataChart: this.configDataChart,
        configSingle: this.configSingle,
        configStyle: this.configStyle,
      };

      var model = {
        reporteDataId: this.reporte.reporteDataId,
        configuracionVisualizacion: JSON.stringify(saveConfig),
      };

      this.showLoading();

      var vm = this;

      this.$http
        .post(
          this.apiRoute("ReportData", "EditConfiguracionVisualizacion"),
          model
        )
        .then(function(response) {
          this.procesarRespuestaGuardarDefecto(response, null, null);
        });
    },
    /*Metodos para verificar la inactividad del usuario*/
    activateActivityTracker: function() {
      window.addEventListener("mousedown", this.userActivityThrottler);
      window.addEventListener("mousemove", this.userActivityThrottler);
      window.addEventListener("scroll", this.userActivityThrottler);
      window.addEventListener("keydown", this.userActivityThrottler);
      window.addEventListener("resize", this.userActivityThrottler);
      window.addEventListener("touchstart", this.userActivityThrottler); //para dispositivos moviles
    },
    deactivateActivityTracker: function() {
      window.removeEventListener("mousedown", this.userActivityThrottler);
      window.removeEventListener("mousemove", this.userActivityThrottler);
      window.removeEventListener("scroll", this.userActivityThrottler);
      window.removeEventListener("keydown", this.userActivityThrottler);
      window.removeEventListener("resize", this.userActivityThrottler);
      window.removeEventListener("touchstart", this.userActivityThrottler); //para dispositivos moviles
    },
    resetUserActivityTimeout: function() {
      clearTimeout(this.userActivityTimeout);

      this.userActivityTimeout = setTimeout(() => {
        this.userActivityThrottler();
        this.inactiveUserAction();
      }, INACTIVE_USER_TIME_THRESHOLD); /*=> INACTIVE_USER_TIME_THRESHOLD */
    },
    userActivityThrottler: function() {
      if (this.isInactive) {
        this.isInactive = false;
      }

      if (!this.userActivityThrottlerTimeout) {
        this.userActivityThrottlerTimeout = setTimeout(() => {
          this.resetUserActivityTimeout();
          clearTimeout(this.userActivityThrottlerTimeout);
          this.userActivityThrottlerTimeout = null;
        }, USER_ACTIVITY_THROTTLER_TIME); /*=> USER_ACTIVITY_THROTTLER_TIME*/
      }
    },
    inactiveUserAction: function() {
      this.isInactive = true;

      Swal.fire({
        icon: "warning",
        title: "Sesión Expirada",
        text: "Su sesión ha expirado. Por favor vuelva a iniciar sesión.",
        confirmButtonText: `Iniciar Sesión`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.deactivateActivityTracker();
          clearTimeout(this.userActivityTimeout);
          clearTimeout(this.userActivityThrottlerTimeout);
          this.$router.push({ name: "login" });
        }
      });
    },
  },
  watch: {
    "configDataChart.showRenderCode": function() {
      this.$nextTick(function() {
        var element = document.getElementById("chartRenderCode");
        element.style.height = "5px";
        element.style.height = element.scrollHeight + 5 + "px";
      });
    },

    "configSingle.showRenderCode": function() {
      this.$nextTick(function() {
        var element = document.getElementById("singleRenderCode");
        element.style.height = "5px";
        element.style.height = element.scrollHeight + 5 + "px";
      });
    },

    "configDataChart.filaTieneTitulo": function() {
      this.configDataChart.componentForceUpdateKey += 1;
    },
    "configDataChart.height": function() {
      this.configDataChart.componentForceUpdateKey += 1;
    },
    "configDataChart.defaultView": function() {
      this.configDataChart.componentForceUpdateKey += 1;
    },
    "configDataChart.pivotTable": function() {
      this.configDataChart.componentForceUpdateKey += 1;
    },
    "configDataChart.pivotReportData": function() {
      this.configDataChart.componentForceUpdateKey += 1;
    },
    "configDataChart.chartType": function(newValue) {
      if (newValue == null) {
        this.configDataChart.defaultView = null;
      }
      this.configDataChart.componentForceUpdateKey += 1;
    },

    "configSingle.filaTieneTitulo": function(newValue) {
      this.configSingle.componentForceUpdateKey += 1;
    },
    "configSingle.pivotTable": function(newValue) {
      this.configSingle.componentForceUpdateKey += 1;
    },

    "configSingle.customRowColumn": function(newValue) {
      this.configSingle.column = null;
      this.configSingle.columnIndex = null;
      this.configSingle.rowIndex = null;
      this.configSingle.componentForceUpdateKey += 1;
    },

    "configSingle.columnIndex": function(newValue) {
      this.configSingle.componentForceUpdateKey += 1;
    },
    "configSingle.column": function(newValue) {
      this.configSingle.componentForceUpdateKey += 1;
    },
    "configSingle.rowIndex": function(newValue) {
      this.configSingle.componentForceUpdateKey += 1;
    },
    "configStyle.colores": function(newValue) {
      this.configSingle.componentForceUpdateKey += 1;
      this.configDataChart.componentForceUpdateKey += 1;
    },
  },
  mounted: async function() {
    this.initParsley();
    this.activateActivityTracker();

    var reporteDataId = this.$route.params.reporteDataId;
    await this.fetchReporte(reporteDataId);
  },
};
</script>
